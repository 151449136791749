<template>
  <div class="our-certifications">
    <div
      class="our-certifications__content__title m-auto col-11 col-sm-10 text-center"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      {{ title }}
    </div>
    <div class="our-certifications__block m-auto col-11 col-sm-10 text-center">
      <div class="row-block mb-3">
        <div
          v-for="(img, indx) in firstRow"
          :key="`first-${indx}`"
          class="col-block"
        >
          <img v-lazy="img" alt="certifications" />
        </div>
      </div>
      <div class="row-block">
        <div
          v-for="(img, indx) in secondRow"
          :key="`second-${indx}`"
          class="col-block"
        >
          <img v-lazy="img" alt="certifications" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OurCertifications",
  data() {
    return {
      title: this.$t("home").ourCertifications.title,
      certifications: [
        "/images/home/certificate_1.png",
        "/images/home/certificate_2.png",
        "/images/home/certificate_3.png",
        "/images/home/certificate_4.png",
        "/images/home/certificate_5.png",
        "/images/home/certificate_6.png",
        "/images/home/certificate_7.png",
        "/images/home/certificate_8.png",
        "/images/home/certificate_9.png",
      ],
      isMobile: window.innerWidth > 640,
    };
  },
  watch: {
    lang() {
      this.title = this.$t("home").ourCertifications.title;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  methods: {
    updateIsMobile() {
      this.isMobile = window.innerWidth > 640;
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
    firstRow() {
      if (this.isMobile) {
        return this.certifications.slice(0, 4);
      }
      return this.certifications;
    },
    secondRow() {
      if (this.isMobile) {
        return this.certifications.slice(4);
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scroped>
.our-certifications {
  background: url("/images/home/our-certificate.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 50px 0 100px;
  padding: 80px 0;
  display: flex;
  gap: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .our-certifications__content__title {
    position: relative;
    font-size: 40px;
    font-weight: 700;
    color: white;
  }
  .our-certifications__block .row-block {
    display: flex;
    justify-content: center;
  }
  .our-certifications__block .col-block {
    margin: 0 50px;
    width: fit-content;
    img {
      width: 150px;
    }
  }
  @media (max-width: 1200px) {
    .our-certifications__block .col-block {
      margin: 0 30px;
      img {
        width: 100px;
      }
    }
  }
  @media (max-width: 767.98px) {
    padding: 40px 0;
    gap: 30px;
    .our-certifications__content__title {
      font-size: 32px !important;
    }
    .our-certifications__block .row-block {
      flex-wrap: wrap;
    }
    .our-certifications__block .col-block {
      padding: 5px;
      margin: 0 10px;
      img {
        width: 60px;
      }
    }
  }
}
</style>
